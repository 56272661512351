import React from 'react'

export const Footerprincipal = () => {
  return(

    <footer className=" container-fluid" >
  
  <div className='container-fluid row justify-content-center'>
  <div className='col-2'></div>
  <div className="row col-md-4 mt-4 p-4 ">
  
  <a className="text-decoration-none Tfooter" href='./Inicio'>INICIO</a>
  <a className="text-decoration-none Tfooter" href='./nosotros'>NOSOTROS</a>
  <a className="text-decoration-none Tfooter" href='./nuestros_servicios'>NUESTROS SERVICIOS</a>
  <a className="text-decoration-none Tfooter" href='./contactanos'>CONTACTANOS</a>
  <a className="text-decoration-none Tfooter" href='./politicaDeDatos'>POLITICA DE TRATAMIENTO DE DATOS</a>
      
    
   
  
</div>
  
  <div className="col-md-4 mt-4 p-4 ">
  <label className="col-12 Tfooter"  >Ubicacion:</label>


  <iframe className="col-12 col-md-6"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.2107970898319!2d-76.50815019065182!3d3.4893775155671674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a9b50300043b%3A0xf7540a8e25a6356e!2sQ-Extreme!5e0!3m2!1ses-419!2sco!4v1696650689713!5m2!1ses-419!2sco" width="300" height="200"  loading="lazy" ></iframe>

  </div>
  </div>
 <div className='col-md-12 text-center'>
  <a className="TCfooter" >©2023 Copyright: www.q-extreme.com</a>
  </div>   
  
</footer>
)
}
