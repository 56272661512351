import React from "react";
import logo from "../recursos/logodinamico.gif";




export const Menuprincipal = () => {
    return(

      
		<div className="text-center p-0 m-0" > 
            	
				<nav className=" navbar navbar-expand-lg Nav position-fixed w-100 border-4 border-bottom " aria-describedby="Menú Q-extreme">
				
				<div className="container-fluid">
				<a href="./Inicio" className="col-md-2 align-self-start" aria-describedby="Logo Q-extreme">
					<div ><img src={logo} className=" logo bg-transparent  col-12 col-sm-8 col-md-6 " aria-describedby="Logo Q-extreme"/>
				  	</div>
				  </a>
				  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
				  <i className="bi bi-list text-dark"></i>
				  </button>
				 
				  <div className="collapse navbar-collapse col-md-9 p-2" id="navbarTogglerDemo01">
					
					<ul className="navbar-nav me-auto mb-2 mb-lg-0 row mt-2 col-md-10 ">
					<li className="Navnav-item col-md-2  ">
						<a className="Nav" aria-current="page" href="./">.</a>
					  </li>
					  <li className="nav-item col-md-2 border-end active">
						<a className="Nav" aria-current="page" href="./Inicio" >Inicio</a>
					  </li>
					  <li className="nav-item col-md-2 border-end">
						<a className="Nav "  aria-current="page" href="./nosotros">Nosotros</a>
					  </li>
					  <li className="nav-item dropdown col-md-3 border-end "   >
						<a className="Nav dropdown-toggle " aria-current="page" href="./nuestros_servicios" data-bs-toggle="dropdown" aria-expanded="false" >Nuestros servicios</a>
						
						
						<ul className="dropdown-menu mt-3 w-100 " >
            				<li><a className="dropdown-item Nav" href="./nuestros_servicios">Pruebas de software</a></li>
							<li><hr className="dropdown-divider "/></li>
           					<li><a className="dropdown-item Nav" href="./nuestros_servicios">Modelamientos de procesos</a></li>
							   <li><hr className="dropdown-divider "/></li>
							<li><a className="dropdown-item Nav" href="./nuestros_servicios">Experencia de usuario</a></li>
						
           					
          </ul>
					  </li>
					  <li className="nav-item col-md-2">
						<a className="Nav" aria-current="page" href="/contactanos">Contáctanos</a>
					  </li>
					</ul>
							  </div>
				</div>
			  </nav>
			  </div>
    )
    
}