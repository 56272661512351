import React, { createRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import lg_facebook from "../recursos/ic_facebook (2).png";
import lg_instagram from "../recursos/ic_instagram.png";
import lg_whatsapp from "../recursos/ic_whatsapp.png";
import lg_linkendin from "../recursos/ic_linkedin.png";
import contacto from "../recursos/contactenos.png"
import {useForm} from "../hooks/useForm";
import { animated,config, useSpring } from '@react-spring/web'

import { useState } from 'react';
import Datos from './leyDatos';







const initialForm={
  name:"",
  email:"",
  number:"",
  message:"",
};


  
const validationForm= (form)=>{
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexComments = /^.{1,255}$/;

 

  if(!form.name.trim()){
    errors.name = "El campo 'Nombre' es requerido";
  }else if(!regexName.test(form.name.trim())){
    errors.name = "El campo 'Nombre' Solo acepta letras y espacios en blanco";
  }else if(!form.email.trim()){
    errors.email = "El campo 'Correo' es requerido";
  }else if(!regexEmail.test(form.email.trim())){
    errors.email = "El campo 'Correo' Solo acepta letras y espacios en blanco";
  }else if(!form.number.trim()){
    errors.number = "El campo 'Numero de contacto' es requerido";
  }else if(!form.message.trim()){
    errors.message = "El campo 'Mensaje' es requerido";
  }else if(!regexComments.test(form.message.trim())){
    errors.message = "El campo 'Mensaje' Solo permite 255 caracteres";
  }else if(form.ley){
   
  }else{ errors.ley = "El campo 'Ley de tratamiento de datos' es requerido";}

 
  

  return errors;
};
let styles = {
  fontWeight:"boldier",
  color:"#dc3545",
  
}

export const ContactForm = (props) => {
  const captcha = createRef(null);  
 const Onchange=()=>{
  if (captcha.current.getValue()){
   
  }
 }
  const {
    form, 
    errors, 
    handleBlur, 
    handleChange, 
    handleSubmit,
  } = useForm(initialForm, validationForm);
 


 
  const {obj, index}=props;
    const[opcion, setOpcion]=useState(false);
    const[opcion2, setOpcion2]=useState(false);
    const[opcion3, setOpcion3]=useState(false);
    const[opcion4, setOpcion4]=useState(false);
 

    const btnredes=useSpring({
      from:{height:opcion ? "150px":"160px", width:opcion?"90%":"95%" },
      to:{height:opcion ? "160px":"150px",width:opcion?"95%":"90%" },
      config:{mass:1,tension:120,friction:14}
  })
  const btnredes2=useSpring({
    from:{height:opcion2 ? "150px":"160px", width:opcion2?"90%":"95%" },
    to:{height:opcion2 ? "160px":"150px",width:opcion2?"95%":"90%" },
    config:{mass:1,tension:120,friction:14}
})
const btnredes3=useSpring({
  from:{height:opcion3 ? "150px":"160px", width:opcion3?"90%":"95%" },
  to:{height:opcion3 ? "160px":"150px",width:opcion3?"95%":"90%" },
  config:{mass:1,tension:120,friction:14}
})
const btnredes4=useSpring({
  from:{height:opcion4 ? "150px":"160px", width:opcion4?"90%":"95%" },
  to:{height:opcion4 ? "160px":"150px",width:opcion4?"95%":"90%" },
  config:{mass:1,tension:120,friction:14}
})




  return (

  
    <div className='container-fluid bgcontact'>
      <div className=' Spaceform2'></div>
      <div className=' col-xl-12 shadow-lg bg-white m-1 rounded border row '>
        <div className='col-xl-12 p-3'></div>

        
            <div  data-aos="zoom-in" data-aos-duration="2000" className='col-xl-6 row '>
                  <img src={contacto} className='borderimg '/>
            </div>
       

        <section data-aos="zoom-in" data-aos-duration="2000" className='container container-sm container-fluid  col-xl-6 d-block mb-5'>
            <div className='container container-sm  container-fluid'>
            <form ref={form} onSubmit={handleSubmit} className='row  col-md-12' >
                 <div className='container container-sm container-fluid col-md-12 row mb-5 text-center'>
                    <span className=' col-md-12 T contactoT mt-5 '>CONTACTENOS</span>
                    <div className='container col-md-3 border-1 border-bottom border-secondary'></div>
                  </div>
                <div className='col-md-6'>
                
                  <div > 
                 
                      <label className='form-label '>Nombres:*</label>
                      {errors.name && <p className=' text-val' style={styles}>{errors.name}</p>}
                      <input type="text" name="name" placeholder="Escriba sus nombres y apellidos"className='form-control mt-3 shadow-lg'onBlur={handleBlur} onChange={handleChange} value={form.name} required/>
                     
                   </div>
                   <div className=''> 
                      <label className='form-label mt-3 '>Email:*</label>
                      {errors.email && <p className='text-val' style={styles}>{errors.email}</p>}
                      <input type="email" name="email" placeholder="Escriba su correo"className='form-control mt-3 shadow-lg' onBlur={handleBlur} onChange={handleChange} value={form.email} required/>
                   </div>
                   <div className=''> 
                      <label className='form-label mt-3 '>Numero de contacto:*</label>
                      {errors.number && <p className=' text-val' style={styles}>{errors.number}</p>}
                      <input type="text" name="number" placeholder="Escriba su numero de teléfono" className='form-control mt-3 shadow-lg' onBlur={handleBlur} onChange={handleChange} value={form.number} required/>
                   </div>
                </div>

                <div className='col-md-6 '>
                   <label className='form-label  '>Mensaje:*</label>
                   {errors.message && <p className=' text-val' style={styles}>{errors.message}</p>}
                   <textarea name="message" placeholder="Escriba su consulta" className='form-control mt-3 h-25 shadow-lg' onBlur={handleBlur} onChange={handleChange} value={form.message} required/>
                   
                   <input type="checkbox" name="ley" onBlur={handleBlur} onChange={handleChange} checked={form.ley} required/><label className='form-label mt-3 '><a href='./politicaDeDatos' target='_blank' >Política de protección de datos :</a> Ley <a href='./leyDatos' target='_blank' >1581 de 2012:</a>*</label>                  
                   {errors.ley && <p className=' text-val' style={styles}>{errors.ley}</p>}
                          
                    <div className='text-center'>
                    <ReCAPTCHA
                   ref={captcha}
    sitekey="6LeyRfEnAAAAADWSBEQ3DqvWu0hXiyVeeM6-t3fp"
    onChange={Onchange}
  />         
                   <input type="submit" value="Solicitar Información" className='shadow  rounded-5 hoverbutton p-3 mt-5 '/> </div>            
                </div>
               
                
                
                
            </form>
            </div>
        </section>
       
        <section className='container-fluid col-12 col-sm-12 col-md-12  col-xl-8  mt-2 text-center'>
            <div className=' row col-xl-12 tam justify-content-center'>
            <div className='col-7  col-sm-2 mb-4 mt-4 row col-3 col-sm-4 col-md-3 col-xl-3'>
              <animated.div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='col-5 col-sm-2 col-xl-3 rounded-5 boxredes boxdesignW'
                 onMouseOver={()=>{ setOpcion(true) }}
                 onMouseOut={()=>{ setOpcion(false) }}
                 
                 style={btnredes}>
                  <a href='https://api.whatsapp.com/send?phone=573125594482' target="_blank" className='text-decoration-none text-black'>
                <div className='mb-4 mt-4'>
                  
                    <img src={lg_whatsapp} className='shadow-lg rounded-circle mx-auto d-block'/>
                </div>

                <span className='redes'>Whatsapp</span></a>
                </animated.div>
                </div>
                <div className='col-7 col-sm-2 mb-4 mt-4 row col-5 col-sm-4 col-md-3 col-xl-3'>
              <animated.div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='col-xl-3 rounded-5 boxredes boxdesignF' 
                  onMouseOver={()=>{ setOpcion2(true) }}
                  onMouseOut={()=>{ setOpcion2(false) }}
                  style={btnredes2}><a href='https://www.facebook.com/Qextreme' target="_blank" className='text-decoration-none text-black'>
                <div className='mb-4 mt-4'>
                    <img src={lg_facebook} className='shadow-lg mx-auto d-block'/>
                </div>
                <span className='redes'>Q-Extreme</span></a>
                </animated.div>
                </div>
                <div className='col-7  col-sm-2 mb-4 mt-4 row col-5 col-sm-4 col-md-3 col-xl-3'>
              <animated.div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='col-xl-3 rounded-5 boxredes boxdesignI' 
                  onMouseOver={()=>{ setOpcion3(true) }}
                  onMouseOut={()=>{ setOpcion3(false) }}
                  style={btnredes3}>
                     <a href='https://www.instagram.com/qextremetecnology/?igshid=NzZhOTFlYzFmZQ%3D%3D' target="_blank" className='text-decoration-none text-black'>
                <div className='mb-4 mt-4'>
                    <img src={lg_instagram} className='shadow-lg rounded-4 mx-auto d-block'/>
                </div> 
                <span className='redes'>qextremetecnology</span></a>
                </animated.div>
                </div>  
                <div className='col-7  col-sm-2 mb-4 mt-4 row col-3 col-sm-4 col-md-3 col-xl-3'>
              <animated.div  data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='col-xl-3 rounded-5 boxredes boxdesignF' 
                  onMouseOver={()=>{ setOpcion4(true) }}
                  onMouseOut={()=>{ setOpcion4(false) }}
                  style={btnredes4}>
                    <a href='https://www.linkedin.com/company/q-extreme/' target="_blank" className='text-decoration-none text-black'>
                <div className='mb-4 mt-4'>
                    <img src={lg_linkendin} className='shadow-lg  mx-auto d-block'/>
                </div> 
                <span className='redes'>Q-Extreme</span></a>
                </animated.div>  
                </div>
                </div>
        </section> 

      </div>

        
      
      </div>
    
  );

};

export default ContactForm;