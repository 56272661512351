import React from 'react'
import modelamiento from "../recursos/ics_Modelamiento.png";
import funcionales from "../recursos/ics_Pruebas_Funcionales.png";
import accesibilidad from "../recursos/ics_Acc_Usa.png";
import carga from "../recursos/ics_Pruebas_Carga.png";
import automatizadas from "../recursos/ics_Automatizacion.png";
import { animated, config, useSpring } from '@react-spring/web';
import { useState } from 'react';
import fondoclaro from "../recursos/lineas_q-e_reversa.mp4"



function ContentServices(props) {
  
  const { obj, index } = props;
  const [opcion3, setOpcion3] = useState(false);

  
  const btnvermasservices = useSpring({
    from: { height: opcion3 ? "60px" : "65px", width: opcion3 ? "70%" : "80%" },
    to: { height: opcion3 ? "65px" : "60px", width: opcion3 ? "80%" : "70%" },
    config: { mass: 1, tension: 110, friction: 14 }
  })

  return (

    <div className="container-fluid row col-12" >
        
      
      <span className='display-5 text-white mt-5'>NUESTROS SERVICIOS</span>
      
      <div className=" slide-container mt-3 ">
        <div className='slide-content '>
            <div className='card-wrapper row  justify-content-center' >
                <div className='card1 shadow-lg m-2 row col-12 col-sm-4 col-md-3 col-xl-2 p-0 ' data-aos="flip-left" data-aos-duration="1500">
                    <div className='image-content  '>
                        <span className='overlay '></span>
                        <div className='card-image'>
                            <img src= {modelamiento} alt="" className='card-img'></img>
                            
                        </div>
                        <h2 className='name '>MODELAMIENTOS DE PROCESOS DE NEGOCIOS Y EXPERIENCIAS DE USUARIO</h2>
                    </div>
                    <div className='card-content'>
                       
                        <p className='description'>Proceso utilizado para representar, documentar, diseñar y optimizar las etapas y el flujo de los
                        procesos de su compañía, con el fin de dimensionar su necesidad de desarrollo software.</p>
                             

                    </div>
                    <div className='col-md-12 p-3'><a href="nuestros_servicios"><animated.button data-aos="flip-up"  className='button shadow '
                                onMouseOver={() => { setOpcion3(true) }}
                                onMouseOut={() => { setOpcion3(false) }}
                                style={btnvermasservices}
                                key={index}>VER MAS</animated.button></a></div>
                </div>
                <div className='card1 shadow-lg m-2 row col-12 col-sm-4 col-md-3 col-xl-2 p-0 ' data-aos="flip-left" data-aos-duration="1500">
                    <div className='image-content '>
                        <span className='overlay'></span>
                        <div className='card-image'>
                            <img src= {funcionales} alt="" className='card-img'></img>
                            
                        </div>
                        <h2 className='name '><br/><br/>PRUEBAS FUNCIONALES</h2>
                    </div>
                    <div className='card-content '>
                       
                        <p className='description '>Estas pruebas consisten en evaluar cada uno de los módulos y funcionalidades del sistema y así,
                validar que el software haga lo que debe, es decir, lo que se le ha sido especificado.</p>
                            

                    </div>
                    <div className='col-md-12 p-3'><a href="nuestros_servicios"><animated.button data-aos="flip-up"  className='button shadow'
                                onMouseOver={() => { setOpcion3(true) }}
                                onMouseOut={() => { setOpcion3(false) }}
                                style={btnvermasservices}
                                key={index}>VER MAS</animated.button></a></div>
                </div>
              
                <div className='card1 shadow-lg m-2 row col-12 col-sm-4 col-md-3 col-xl-2  p-0 ' data-aos="flip-left" data-aos-duration="1500">
                    <div className='image-content '>
                        <span className='overlay'></span>
                        <div className='card-image'>
                            <img src= {carga} alt="" className='card-img'></img>
                            
                        </div>
                        <h2 className='name '><br/><br/>PRUEBAS DE CARGA</h2>
                    </div>
                    <div className='card-content'>
                       
                        <p className='description '>Las pruebas de carga se utilizan para evaluar la funcionalidad de un programa de software cuando
                se somete a distintas cargas, por ejemplo, partiendo de un cálculo aproximado de cuantos
                usuarios soporta el programa.</p>
                            

                    </div>
                    <div className='col-md-12 p-3'><a href="nuestros_servicios"><animated.button data-aos="flip-up"  className='button shadow'
                                onMouseOver={() => { setOpcion3(true) }}
                                onMouseOut={() => { setOpcion3(false) }}
                                style={btnvermasservices}
                                key={index}>VER MAS</animated.button></a></div>
                </div>
                <div className='card1 shadow-lg m-2 row col-12 col-sm-4 col-md-3 col-xl-2  p-0 ' data-aos="flip-left" data-aos-duration="1500"> 
                    <div className='image-content '>
                        <span className='overlay'></span>
                        <div className='card-image'>
                            <img src= {automatizadas} alt="" className='card-img'></img>
                            
                        </div>
                        <h2 className='name '><br/><br/>PRUEBAS AUTOMATIZADAS</h2>
                        
                    </div>
                    <div className='card-content'>
                       
                        <p className='description'>Automatizar las pruebas te puede ahorrar esfuerzos manuales, potencializando la productividad y efectividad; escríbenos para evaluar si este es el tipo de prueba que necesitas para tu producto.</p>
                             

                    </div>
                   <div className='col-md-12 p-3'><a href="nuestros_servicios"><animated.button data-aos="flip-up"  className='button shadow'
                                onMouseOver={() => { setOpcion3(true) }}
                                onMouseOut={() => { setOpcion3(false) }}
                                style={btnvermasservices}
                                key={index}>VER MAS</animated.button></a></div>
                    
                </div>
                <div className='card1 shadow-lg m-2 row col-12 col-sm-4 col-md-3 col-xl-2 p-0 ' data-aos="flip-left"  data-aos-duration="1500">
                    <div className='image-content '>
                        <span className='overlay'></span>
                        <div className='card-image'>
                            <img src= {accesibilidad} alt="" className='card-img'></img>
                            
                        </div>
                        <h2 className='name '><br/>PRUEBAS DE USABILIDAD Y ACCESIBILIDAD</h2>
                    </div>
                    
                    <div className='card-content'>
                       
                        <p className='description'>Consiste en medir la capacidad de un sistema en cuanto a la adaptación, funcionalidad y facilidad
                de uso en diferentes interfaces y dispositivos.</p>
                             

                    </div>
                    
                    <div className='col-md-12 p-3'><a href="nuestros_servicios"><animated.button data-aos="flip-up"  className='button shadow'
                                onMouseOver={() => { setOpcion3(true) }}
                                onMouseOut={() => { setOpcion3(false) }}
                                style={btnvermasservices}
                                key={index}>VER MAS</animated.button></a></div>
                </div>
                
            </div>
        </div>
            
      </div>
      
        
      
     
    </div>

  )
  
}

export default ContentServices;