
import React from 'react'

import { animated, config, useSpring } from '@react-spring/web'

import { useState } from 'react';
import ContentServices from './contentservices';
import fondo from "../recursos/videofondooscuro.mp4";
import certi_1 from "../recursos/Certi-ASTFC.png";
import certi_2 from "../recursos/Certi-SFPC.png";
import certi_3 from "../recursos/Certi-ISO9001.png";




function Inicio(props) {
  const { obj, index } = props;
  const [opcion, setOpcion] = useState(false);
  const [opcion2, setOpcion2] = useState(false);


  const btnvermas = useSpring({
    from: { height: opcion ? "55px" : "60px", width: opcion ? "80%" : "90%" },
    to: { height: opcion ? "60px" : "55px", width: opcion ? "90%" : "80%" },
    config: { mass: 1, tension: 120, friction: 14 }
  })
  const btnconocenos = useSpring({
    from: { height: opcion2 ? "55px" : "60px", width: opcion2 ? "85%" : "95%" },
    to: { height: opcion2 ? "60px" : "55px", width: opcion2 ? "95%" : "85%" },
    config: { mass: 1, tension: 120, friction: 14 }
  })

  return (



    <div className='text-center' >


      <section className=' container-fluid col-12 col-sm-12  col-md-12 col-xl-12 App-section p-0'>
        <div className="header-video">
          <video autoPlay loop muted playsInline className=''>
            <source src={fondo} type='video/mp4' />
          </video>
        </div>
        <div className="header-overlay"></div>
        <div className="container-fluid ">
          <div className="Space"></div>
          <div className='container-fluid col-md-12 row p-4 '><div className="col-md-1"></div><div data-aos="zoom-in" data-aos-duration="1000" className="col-md-10"><span className='display-1 fw-bolder text-white col-md-12 TitleP'> Q-EXTREME SAS SERVICIOS REMOTOS DE QA</span></div><br />
            <p data-aos="zoom-in" data-aos-duration="1500" className='display-7 col-md-12 text-white fw-bolder TitleP'>FORTALECIMIENTO DE BUENAS PRACTICAS PARA SUS PROYECTOS DE TECNOLOGIA Y DESARROLLO DE SOFTWARE</p>

            <div data-aos="flip-up" data-aos-duration="2000" className='container-fluid col-12 col-md-3'>
              <a href="#Section1" >
                <animated.button className='hoverbutton rounded-pill bg-transparent border-white mb-5 text-white p-3 '
                  onMouseOver={() => { setOpcion(true) }}
                  onMouseOut={() => { setOpcion(false) }}
                  style={btnvermas}>VER MAS</animated.button></a>
            </div>
          </div>
        </div>
      </section>

      <section id="Section1" className='container-fluid col-md-12 App-section2 ' >
     
         <div className="header-video2 ">
         <div className="hero2"> 
  
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
</div>
         
         
          
        </div>
        <div className="header-overlay2"></div>
        <div className='row justify-content-center'>
        
        

        <div className=' col-12 col-sm-12 col-md-5 col-xl-6  mt-5 subsection1'><p className='T'>Estamos trabajando en el feed de nuestras redes<br/>ESPERANOS PRONTO<br/></p></div>
        <div  data-aos="zoom-in" className='col-12 col-sm-12 col-md-5 col-xl-4 p-5 mt-5 shadow-lg  subsection2'><span className="card-title T">LOS SERVICIOS DE Q-EXTREME SON EL APOYO PERFECTO PARA LOS PROYECTOS DE TECNOLOGIA</span>
          <p className="card-text P mt-2" style={{ textAlign: "justify" }}>

            - Tercerización de áreas de QA y pruebas de software.<br />

            - Fortalecimiento de áreas de pruebas de software.<br />

            - Alistamiento de procesos y requerimientos para equipos de desarrollo.<br />

            - Modelamiento de procesos y desarrollo de software con tecnología de punta.

          </p><a href='./nosotros#obj'><animated.button className='hoverbutton shadow p-2  rounded-5  '
            onMouseOver={() => { setOpcion2(true) }}
            onMouseOut={() => { setOpcion2(false) }}
            style={btnconocenos}
            key={index}>CONOCE MAS</animated.button></a>
        </div>


        </div>
      </section>
      <section className='container-fluid col-md-12 App-section2   ' >
   
      <div className="header-video3 ">
      <div className="hero"> 
  
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
</div>
         
          
        </div>
        <div className="header-overlay3"></div>
        
        
  
        <ContentServices/>

   

      </section>
      <section data-aos="zoom-in" className='container-fluid col-12 col-sm-8 col-md-10 col-xl-12row border-4  '>
      <div className="header-video2 ">
         <div className="hero2"> 
  
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
</div>
         
         
          
        </div>
        
        <div className="container-fluid mt-5">

          <span className='display-5 col-md-12'> FLEXIBILIDAD DEL SERVICIO</span>
          <div className='container-fluid row p-4 mt-5'><div className="col-md-1"></div><br />
            <p className='display-7  col-12 col-md-12'>Uno de nuestros principales valores diferenciadores es la flexibilidad, lo que le permite a Q-Extreme
              SAS, definir estrategias que se adaptan a las necesidades de cada uno de los proyectos de tecnología.
              De esta manera se logra estructurar servicios en diferentes modalidades como, tiempo completo, medio
              tiempo y horas, etc.
              Permítenos crear una estrategia personalizada que se ajuste a tus necesidades.</p>

            <div data-aos="flip-up" className='container-fluid col-12 col-sm-10 col-md-6 col-xl-3'>
              <a href="./contactanos" >
                <animated.button className='hoverbutton shadow p-3 mb-5 rounded-5 mt-5 '
                  onMouseOver={() => { setOpcion2(true) }}
                  onMouseOut={() => { setOpcion2(false) }}
                  style={btnconocenos}
                  key={index}>CONTACTANOS</animated.button></a>
            </div>
          </div>
        </div>

      </section>
      <section  className='App-section3 ' > 
      <div className="header-video2">
      <div className="hero"> 
  
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
  <div className="cube"></div>
</div>

          
        </div>
                <div className="shadow-lg col-12  text-center">
                
                    <div className=' container-fluid p-5 T text-white col-8'><span className='display-4 '>Compromiso social</span>
                        <p className="p mt-5"> Apoyamos la inclusión laboral de profesionales con diversidad funcional. En el 2020 fuimos ganadores de la convocatoria 76 del fondo emprender del sena para emprendedores con diversidad funcional.</p>
                    </div>
                    
                    
                </div>
            </section>

            <section data-aos="zoom-in" className='container-fluid col-12 col-sm-8 col-md-12 col-xl-12 border-4  '>
      <div className="header-video2 ">
         <div className="hero2"> 
  
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
  <div className="cube2"></div>
</div>
         

          
        </div>
        
        
        <div className="container-fluid p-4">
          <div className='mt-5 '>
          <span className=' Tcerti col-md-12 '>CERTIFICACIONES</span>
          
            <div className='container-fluid  p-4 mt-5 col-12 col-sm-12 col-md-12 col-xl-12'>
            <div className='justify-content-center row p-2 '>
                  <div className=' col-8 col-sm-6 col-md-6 col-xl-2 col-xxl-3'>
                    <img src={certi_1} className='certificados '/>
                    <span className=' col-8 P'>Accredited Software Testing Fundamentals</span>
                 </div>

                 <div className='col-7 col-sm-6 col-md-6 col-xl-2 col-xxl-3'>
                   <img src={certi_2} className='certificados'/>
                   <span className='col-12 P '>Scrum Foundation Professional</span>
                 </div>

                 <div className='col-8 col-sm-6 col-md-6 col-xl-2 col-xxl-3'>
                   <img src={certi_3} className='certificados '/>
                   <span className='col-10 P'>Iso 9001 Certification</span>
                 </div>


                 
              </div>
            </div>

            
          
          </div>
        </div>

      </section>


    </div>









  );
}
export default Inicio;
