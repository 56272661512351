
import React from "react";
import fondo from "../recursos/lineas_q-e_reversa.mp4"
import funcionales from "../recursos/ics_Pruebas_Funcionales.png"
import modelamiento from "../recursos/ics_Modelamiento.png";
import accesibilidad from "../recursos/ics_Acc_Usa.png";
import carga from "../recursos/ics_Pruebas_Carga.png";
import automatizadas from "../recursos/ics_Automatizacion.png";
import { animated, config, useSpring } from '@react-spring/web'

import { useState } from 'react';


export const Nuestros_servicios = (props) => {
    const { obj, index } = props;
    const [opcion, setOpcion] = useState(false);
    const [opcion2, setOpcion2] = useState(false);


    const btnvermas = useSpring({
        from: { height: opcion ? "55px" : "60px", width: opcion ? "80%" : "90%" },
        to: { height: opcion ? "60px" : "55px", width: opcion ? "90%" : "80%" },
        config: { mass: 1, tension: 120, friction: 14 }
    })
    const btnconocenos = useSpring({
        from: { height: opcion2 ? "55px" : "60px", width: opcion2 ? "85%" : "95%" },
        to: { height: opcion2 ? "60px" : "55px", width: opcion2 ? "95%" : "85%" },
        config: { mass: 1, tension: 120, friction: 14 }
    })
    return (

        <div className='container-fluid row p-1 text-center' >
           <section className='container-fluid col-md-12 App-section   ' >
            <div className="header-video3 ">
   <div className="hero3"> 

<div className="cube3"></div>
<div className="cube3"></div>
<div className="cube3"></div>
<div className="cube3"></div>
<div className="cube3"></div>
<div className="cube3"></div>
</div>
      
       
     </div>
    
     
     <div className='container-fluid col-md-12 row p-4 '>
        
        <div className="Space"></div>
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-12 text-center ">
            <span className='display-1 fw-bolder col-md-12 titleC T'> PRUEBAS DE SOFTWARE</span>
        </div>
            
              
            
          </div>
          </section>
            <div className="container-fluid col-xl-12 row ">

                <section data-aos="fade-right" className="  row col-md-8  bgserviced p-5 shadow ">
                    <div className="col-12"><span className="card-title T">PRUEBAS FUNCIONALES</span>
                        <p className="PT">Las pruebas funcionales se realizan en una variedad de etapas del ciclo de vida del software, desde la fase de diseño hasta la fase de producción. En la fase de diseño, las pruebas funcionales se utilizan para validar los requisitos del software. En la fase de desarrollo, las pruebas funcionales se utilizan para verificar que el software implementa los requisitos correctamente. En la fase de prueba, las pruebas funcionales se utilizan para identificar y corregir errores en el software. </p>
                    </div>
                    <div className="col-12"><img src={funcionales} alt=""/></div>
                    <div className="col-12">
                        <span className="card-title T">Beneficios</span>
                        <p className="PT">
                            Asegura de que el software está libre de defectos.<br />

                            Garantiza el cumplimiento de todas las funcionalidades.<br />

                            Garantiza que la arquitectura sea correcta y mejora la calidad del producto<br />

                            Minimiza los riesgos organizacionales asociados con el software

                        </p>
                        <a href="./contactanos" >
                            <animated.button className='shadow p-3 hoverbuttonNS rounded-pill bg-transparent '
                                onMouseOver={() => { setOpcion2(true) }}
                                onMouseOut={() => { setOpcion2(false) }}
                                style={btnconocenos}
                                key={index}>CONTACTANOS</animated.button></a>
                    </div>
                </section>
                <div className="col-md-3 container-fluid"></div>
                <div className="col-md-3 container-fluid"></div>
                <section data-aos="fade-left" className=" shadow  container-fluid row  col-md-8 bgservicei p-5">

                    <div className="col-12"><span className="card-title T">PRUEBAS DE CARGA</span>
                        <p className="PT">
                            Las pruebas de carga son un tipo de pruebas de rendimiento que se centran en verificar cómo un sistema se comporta bajo una carga pesada. En otras palabras, las pruebas de carga se aseguran de que un sistema puede manejar un volumen de tráfico esperado sin degradar su rendimiento.

                            Las pruebas de carga se pueden realizar en cualquier tipo de sistema, desde sitios web y aplicaciones web hasta bases de datos y sistemas distribuidos. El objetivo de las pruebas de carga es identificar los cuellos de botella en el sistema y garantizar que el sistema pueda manejar la carga esperada sin afectar la experiencia del usuario. </p>
                    </div>
                    <div className="col-12"><img src={carga} alt=""/></div>
                    <div className="col-12 ">
                        <span className="card-title T">Beneficios</span>
                        <p className="PT">
                            Mejora la fiabilidad de la aplicación.<br />

                            Garantiza la escalabilidad<br />

                            Mejora la experiencia del usuario<br />

                            Mejora la eficiencia<br />

                            Reduce los costes
                        </p>
                        <a href="./contactanos" >
                            <animated.button className='shadow p-3 hoverbuttonNS rounded-pill bg-transparent  '
                                onMouseOver={() => { setOpcion2(true) }}
                                onMouseOut={() => { setOpcion2(false) }}
                                style={btnconocenos}
                                key={index}>CONTACTANOS</animated.button></a>
                    </div>

                </section>
                <div className="col-md-3 container-fluid"></div>
                <div className="col-md-3 container-fluid"></div>
                <section data-aos="fade-right" className=" shadow container  container-fluid row col-md-8 rounded-right bgserviced p-5">
                    <div className="col-12"><span className="card-title T">PRUEBAS AUTOMATIZADAS</span>
                        <p className="PT">Las pruebas automatizadas son una parte esencial en el ciclo de desarrollo de software. Este proceso implica el uso de herramientas y scripts para ejecutar pruebas de manera sistemática y repetitiva, sin la intervención manual constante. Esto ofrece una serie de beneficios cruciales para los equipos de desarrollo y las organizaciones en general. </p>
                    </div>
                    <div className="col-12"><img src={automatizadas} alt=""/></div>
                    <div className="col-12">
                        <span className="card-title T">Beneficios</span>
                        <p className="PT">
                            Reduce tiempos y costos<br />
                            Aumenta la productividad de los equipos de trabajo<br />
                            Se consigue un resultado más preciso<br />
                            Rápida Retroalimentación<br />
                            Regresión y Mantenimiento

                        </p>
                        <a href="./contactanos" >
                            <animated.button className='shadow p-3 hoverbuttonNS rounded-pill bg-transparent  '
                                onMouseOver={() => { setOpcion2(true) }}
                                onMouseOut={() => { setOpcion2(false) }}
                                style={btnconocenos}
                                key={index}>CONTACTANOS</animated.button></a>
                    </div>
                </section>
                <div className="col-md-3 container-fluid"></div>
                <div className="col-md-3 container-fluid"></div>
                <section data-aos="fade-left" className=" shadow container container-fluid row col-md-8 rounded-right bgservicei p-5" >

                    <div className="col-12"><span className="card-title T">PRUEBAS ACCESIBILIDAD Y USABILIDAD</span>
                        <p className="PT">Las pruebas de usabilidad y accesibilidad son dos tipos de pruebas de software que se centran en diferentes aspectos de la experiencia del usuario. Las pruebas de usabilidad se centran en verificar que el software es fácil de usar y comprender, mientras que las pruebas de accesibilidad se centran en verificar que el software es accesible para personas con discapacidades. </p>
                    </div>
                    <div className="col-12"><img src={accesibilidad} alt=""/></div>
                    <div className="col-12">
                        <span className="card-title T">Beneficios</span>
                        <p className="PT">
                            Incrementa la cuota de mercado y audiencia<br />
                            Mejora los resultados en los buscadores<br />
                            Permite abordar problemas del producto, antes de gastar dinero en un diseño deficiente<br />
                            Optimiza la experiencia de todos los usuarios
                        </p>
                        <a href="./contactanos" >
                            <animated.button className='shadow p-3 hoverbuttonNS rounded-pill bg-transparent '
                                onMouseOver={() => { setOpcion2(true) }}
                                onMouseOut={() => { setOpcion2(false) }}
                                style={btnconocenos}
                                key={index}>CONTACTANOS</animated.button></a>
                    </div>

                </section>

                <section data-aos="fade-right" className=" shadow container  container-fluid row col-md-8 rounded-right bgserviced p-5">
                    <div className="col-12"><span className="card-title T">MODELAMIENTO DE PROCESOS DE NEGOCIO Y NECESIDADES DE USUARIO</span>
                        <p className="PT">El modelado de procesos de negocio y el modelado de necesidades de usuario están estrechamente relacionados. El modelado de procesos de negocio puede ayudar a las organizaciones a comprender las necesidades de los usuarios, mientras que el modelado de necesidades de usuario puede ayudar a las organizaciones a diseñar procesos de negocio que satisfagan esas necesidades. </p>
                    </div>
                    <div className="col-12"><img src={modelamiento} alt=""/></div>
                    <div className="col-12">
                        <p className="PT">Este servicio les permite a nuestros clientes conocer las necesidades de sus usuarios(UX/UI) y así poder tomar mejores decisiones respecto al producto que requiere desarrollar o adquirir</p>
                        <a href="./contactanos" >
                            <animated.button className='shadow p-3 hoverbuttonNS rounded-pill bg-transparent '
                                onMouseOver={() => { setOpcion2(true) }}
                                onMouseOut={() => { setOpcion2(false) }}
                                style={btnconocenos}
                                key={index}>CONTACTANOS</animated.button></a>
                    </div>
                </section>
                <div className="col-md-3 container-fluid"></div>
            </div>
        </div>
    )

}

export default Nuestros_servicios;