import { useState } from "react";
import emailjs from '@emailjs/browser';

export const useForm=(initialForm, validateForm)=>{

const[form,setForm]=useState(initialForm);
const[errors,setErrors]=useState({});


const handleChange = (e) =>{
 const {name, email, number, message, ley,value, checked}= e.target;



    setForm({
        ...form,
    [name]:value,
    [email]:value,
    [number]:value,
    [message]:value,
    [ley]:checked
    });

};
const handleBlur = (e) =>{
 
    handleChange(e);
    setErrors(validateForm(form));


};

const handleSubmit = (e) =>{

    e.preventDefault();     


    emailjs.sendForm('service_n2zdwf9', 'template_8xtey3n', form.current, 'taGZOWM2uhxnTcBME')
      .then((result) => {
        alert("Formulario enviado con exito")
        console.log(result.text);
        document.location.reload()
      }, (error) => {
        alert(error.text)
        console.log(error.text);
      });
      
   
};


return{
form, 
errors, 
handleBlur, 
handleChange, 
handleSubmit,

}

};
