
import './App.css';
import './components/ContactForm.css';
import './components/politicaDatos.css';
import { Menuprincipal } from './components/menu';
import { Footerprincipal } from './components/footer';


import  Router  from './Router';



function App() {
 
  return (
    
       
        
      <div className="App">
            
            
            <Menuprincipal/>
          
            <Router/>
                      
            <Footerprincipal/>
            
      </div>


        
        
      
      
    
    
  );
}
export default App;




