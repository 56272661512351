import React from 'react'

export const PoliticaDatos = () => {


  return(

  
 
  <div class="container-fluid">
    <div class="Space"></div>
    <div class="text-center"> <span class="display-5">POLÍTICA DE PROTECCIÓN DE DATOS</span></div>
   
    <div class="container mt-5">
  
        <div class="Espacios-div">
            <span class="Title-politicas">OBJETIVOS</span>
            <p class="mt-4">Definir los criterios para el Tratamiento de datos personales que reposa en las bases de datos, archivos físicos y digitales de Q-EXTREME S.A.S. </p>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">ALCANCE</span>
            <p class="mt-4">Esta política establece los criterios que Q-EXTREME S.A.S ha definido para el adecuado manejo de los datos personales, acorde a la Ley 1581 del 2012 y su decreto reglamentario 1377 del 2013.</p>
            <p class="mt-4">Estos criterios aplican a colaboradores, proveedores, contratistas, clientes, encargados, terceros en general y titulares de información personal.</p>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">PRINCIPIOS FUNDAMENTALES DE LA POLÍTICA</span>
            <p class="mt-4">Q-EXTREME S.A.S se compromete a seguir los siguientes principios en el tratamiento de los datos personales:</p>
            <ul>
                <li> - Legalidad: Nos comprometemos a tratar los datos personales de acuerdo con lo establecido en la legislación vigente.</li>
                <li> - Finalidad: Los datos personales serán recolectados y tratados únicamente para las finalidades específicas informadas a los titulares de los datos.</li>
                <li> - Veracidad: Nos comprometemos a mantener actualizada la información personal de los titulares y a garantizar su veracidad.</li>
                <li> - Transparencia: Informaremos de manera clara y comprensible a los titulares sobre el tratamiento que daremos a sus datos personales.</li>
                <li> - Seguridad: Adoptaremos las medidas técnicas, administrativas y humanas necesarias para proteger los datos personales contra cualquier pérdida, acceso no autorizado, uso, alteración o divulgación indebida.</li>
                <li> - Confidencialidad: Garantizamos la confidencialidad de los datos personales tratados y nos comprometemos a no divulgarlos salvo autorización expresa del titular o cuando sea requerido por ley.</li>
            </ul>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">RESPONSABLE DE TRATAMIENTO</span>
            <p class="mt-4">El responsable del tratamiento de los datos personales será Q-EXTREME S.A.S. con Nit. 901.455.506 teléfono 3125594482 o a través del correo electrónico proyecto@q-extreme.com</p>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">FINALIDADES DE TRATAMIENTO</span>
            <p class="mt-4">Los datos personales serán tratados para las siguientes finalidades:</p>
            <ul>
                <li> - Gestión de clientes, proveedores.</li>
                <li> - Gestión de colaboradores, excolaboradores y futuros colaboradores.</li>
                <li> - Cumplimiento de obligaciones legales y contractuales.</li>
                <li> - Realización de estudios de mercado y análisis estadísticos.</li>
                <li> - Facilitar el ejercicio de supervisión o interventoría a la ejecución del contrato.</li>
            </ul>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">DERECHOS DE LOS TITULARES DE LOS DATOS</span>
            <p class="mt-4">Los titulares de los datos personales tienen derecho a:</p>
            <ul>
                <li> - Conocer, actualizar y rectificar sus datos personales.</li>
                <li> - Solicitar la supresión de sus datos personales cuando no sean necesarios para las finalidades informadas.</li>
                <li> - Revocar el consentimiento otorgado para el tratamiento de sus datos personales.</li>
                <li> - Acceder en forma gratuita a sus datos personales objeto de tratamiento.</li>
            </ul>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">PROCEDIMIENTO PARA EJERCER LOS DERECHOS</span>
            <p class="mt-4">Los titulares podrán ejercer sus derechos enviando una solicitud escrita al responsable del tratamiento a través de los medios de contacto mencionados. La solicitud deberá contener la siguiente información:</p>
            <p class="mt-4">Nombre completo del titular.</p>
            <p class="mt-4">Número de identificación.</p>
            <p class="mt-4">Descripción clara y precisa de los derechos que desea ejercer.</p>
        </div>  
        <div class="Espacios-div">
            <span class="Title-politicas">MEDIOS DE CONTACTO</span>
            <p class="mt-4">Página web www.q-extreme.com</p>
            <p>Teléfono o WhatsApp: 312 5594482</p>
            <p>Correo: proyecto@q-extreme.com</p>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">DEFINICIONES DE LEY</span>
            <p class="mt-4">Para efectos de la presente política, los términos que se señalan a continuación tendrán los siguientes significados, los cuales fueron tomados de la Ley 1581 de 2012 y del Decreto 1377 de 2013:</p>
            <p class="mt-4">Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.</p>
            <p class="mt-4">Base de Datos: Conjunto organizado de Datos Personales que sea objeto de Tratamiento.</p>
            <p class="mt-4">Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. Son algunos ejemplos de datos personales los siguientes: nombre, cédula de ciudadanía, dirección, número telefónico, estado civil, datos de salud, huella dactilar, etc.</p>
            <p class="mt-4">Dato público: Son los datos no establecidos como semiprivados, privados o sensibles. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.</p>
            <p class="mt-4">Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos.</p>
            <p class="mt-4">Titular: Persona natural cuyos Datos Personales sean objeto de Tratamiento, entiéndanse clientes o usuarios, colaboradores, proveedores y demás individuos relacionados con Q-EXTREME S.A.S.</p>
            <p class="mt-4">Tratamiento: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
            <p class="mt-4">Aviso de Privacidad: Comunicación verbal o escrita generada por el responsable, dirigida al Titular para el Tratamiento de sus Datos Personales, mediante la cual se le informa acerca de la existencia de las Políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.</p>
            <p class="mt-4">Dato sensible: Información que afecta la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros, la captura de imagen fija o en movimiento, huellas digitales, fotografías, iris, reconocimiento de voz, facial o de palma de mano, etc.</p>
        </div>
        <div class="Espacios-div">
            <span class="Title-politicas">VIGENCIA</span>
            <p class="mt-4">La presente actualización de la política de tratamiento de datos entra en vigor a partir del 15 de febrero 2024.</p>
            <p class="mt-4">Esta política de protección de datos personales se comunicará a todos los empleados, contratistas y demás personas que actúen en nombre de Q-EXTREME S.A.S y estará disponible para consulta por parte de los titulares de los datos personales en nuestra página web y en nuestras instalaciones físicas.</p>
        </div>
    </div>
</div>
  
  
)
}
export default PoliticaDatos;