import React from 'react'


export const Datos = () => {
    
    
    
    return(

            <div>
                <div className=' Spaceform'></div>
                <div className='container-fluid p-5 row'>
                <span className='text-center mb-5'>Ley de Datos</span>
                <span className='mb-5'>1. INTRODUCCIÓN</span>    
                    <p className='text-center'>
Tomando como referencia el Articulo 15 de la Constitución Política de Colombia, en donde tiene como
objeto conocer, actualizar y rectificar la información que se haya recogido en las bases de datos y archivos,
en el Artículo 20, encontramos una referencia a que todas las personas tenemos derecho a la información.
Dando cumplimiento a lo anterior, se hace necesario establecer para el Ministerio de Ambiente y
Desarrollo Sostenible, la conformación de una política consiente de la responsabilidad que le asiste en
materia de Tratamiento y Protección de Datos Personales.
La aplicación es de carácter obligatorio, para todas las personas naturales o jurídicas que hagan
tratamiento a los datos personales registrados en las diferentes bases de datos del Ministerio de Ambiente
y Desarrollo Sostenible, esto con el fin de proporcionar la línea base necesaria en aras de dar
cumplimiento a las obligaciones legales en materia de protección de datos personales.
El Ministerio de Ambiente y Desarrollo Sostenible, informa por medio de la presente que: los datos
personales que se obtengan en virtud de la operación en donde se celebren o se celebren con la Entidad,
serán tratados conforme a los principios establecidos en la Ley 1581 de 2012 y demás normas y decretos
que tengan relación y regulen la materia. Para tales fines, se tendrá como domicilio del Ministerio de
Sostenible y Desarrollo Sostenible:
Ministerio de Ambiente y Desarrollo Sostenible con domicilio en la calle 37 No. 8-40 - Bogotá –
Colombia, identificado con el Número de Identificación Tributaria NIT 830.115.395-1. Línea gratuita
fuera de Bogotá: 018000915060 de lunes a viernes de 8:00 a.m. a 5:00 p.m. Contestador automático
de lunes a lunes de 6:00 p.m. a 8:00 a.m. Línea gratuita Bogotá +57 (1) 3323400 de lunes a viernes
de 8:00 a.m. a 6:00 p.m. Chat virtual (minambiente.gov.co) PBX: +57 (1) 3323422: De lunes a viernes
de 8:00 a.m. a 6:00 p.m. Correo electrónico: servicioalciudadano@minambiente.gov.co</p>
<span className='mb-5 mt-5'>1. ALCANCE</span>
<p>

La presente Política de Tratamiento y Protección de Datos Personales, se aplicará a todas las bases de
datos y archivos que contengan datos personales y que sean objeto de tratamiento por el Ministerio de
Ambiente y Desarrollo Sostenible, considerado como responsable o encargado del tratamiento de los
datos personales.
No obstante, cabe señalar que se debe brindar en primera instancia, la protección a los intereses y
necesidades de los titulares de la información personal tratada por el Ministerio de Ambiente y Desarrollo
Sostenible; priorizar el cumplimiento a las exigencias de la normatividad vigente en materia de Protección
de Datos Personales, al igual que cualquier exigencia originada en el principio de responsabilidad
demostrada.
Todos los funcionarios, Contratistas y terceros que tengan relación con el Ministerio de Ambiente y
Desarrollo Sostenible y que ejerzan tratamiento sobre las bases de datos personales, deben cumplir con
esta política y los procedimientos establecidos para el tratamiento de los datos personales.
</p>

<span className='mb-5 mt-5'>2. DESTINATARIOS</span>
<p>
Esta política está dirigida a toda la ciudadanía en general que tenga a su disposición la información
necesaria y suficiente sobre los tratamientos y fines, sobre los que serán objeto de sus datos. Así como,
los derechos que tienen como titulares y que en cualquier momento podrán ejercer ante el Ministerio de
Ambiente y Desarrollo Sostenible, cuando este tenga la responsabilidad del tratamiento de los mismo.
La aplicación de esta política se encuentra encarada en todas las bases de datos, físicas, digitales e
hibridas y que sean objeto de tratamiento por parte del Ministerio de Ambiente y Desarrollo Sostenible,
considerando como responsable. Igualmente, en los casos que funcione como encargadas del tratamiento
de los datos personales.
La presente política es de carácter obligatorio cumplimiento por todas las personas naturales y jurídicas
responsables de la administración y gestión de bases de datos personales del Ministerio de Ambiente y
Desarrollo Sostenible en especial los responsables encargados de gestionar las bases de datos, en
especial los grupos que atienden la gestión documental, atienden y dan respuesta a las peticiones quejas
y reclamos, específicamente las que tengan relación con la Ley de proyección de Datos Personales.</p>

<span className='mb-5 mt-5'>3. PRINCIPIOS RECTORES</span>
<p>
En virtud de interpretación y aplicación jurídica, se aplicarán los siguientes principios:
a) Principio de Legalidad en Materia de Tratamiento de Datos: El Tratamiento es una actividad
reglada que debe sujetarse a lo establecido en la Ley 1581 del 17 de octubre de 2012, decretos
reglamentarios y demás disposiciones que la desarrollen.
b) Principio de Finalidad: El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la
Constitución y la Ley, la cual debe ser informada al Titular.
c) Principio de Libertad: El Tratamiento sólo puede ejercerse con el consentimiento, previo,
expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin
previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.
d) Principio de Veracidad o Calidad: La información sujeta a Tratamiento debe ser veraz, completa,
exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de Datos parciales,
incompletos, fraccionados o que induzcan a error.
e) Principio de Transparencia: En el Tratamiento debe garantizarse el derecho del Titular a obtener
del responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin
restricciones, información acerca de la existencia de datos que le conciernan.
f) Principio de Acceso y Circulación Restringida: El Tratamiento se sujeta a los límites que se
derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución.
En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por
las personas previstas en la ley. Los datos personales, salvo la información pública, no podrán
estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el
acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares
o terceros autorizados.
g) Principio de Seguridad: La información sujeta a Tratamiento por el responsable del Tratamiento
o Encargado del Tratamiento a que se refiere la ley, se deberá manejar con las medidas técnicas,
humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su
adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
h) Principio de Confidencialidad: Todos los funcionarios y contratistas que intervengan en el
Tratamiento de Datos Personales que no tengan la naturaleza de públicos están obligadas a
garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de
las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de
datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y
en los términos de la misma. El Ministerio de Ambiente y Desarrollo Sostenible, se compromete a
tratar los datos personales de los titulares tal y como lo define el literal g) del artículo 3 de la Ley
1581 de 2012 de forma absolutamente confidencial haciendo uso de estos, exclusivamente, para las finalidades indicadas en el apartado anterior, siempre que el titular no se haya opuesto a dicho
tratamiento. El Ministerio de Ambiente y Desarrollo Sostenible, informa que tiene implantadas las
medidas de seguridad de índole técnica y organizativas necesarias que garanticen la seguridad de
sus datos personales y eviten su alteración, pérdida, tratamiento y/o acceso no autorizado.
i) Principio de temporalidad: Los datos personales se conservarán únicamente por el tiempo
razonable y necesario para cumplir las finalidades que justificaron el tratamiento, atendiendo a las
disposiciones aplicables a la materia de que se trate y a los aspectos administrativos, contables,
fiscales, jurídicos e históricos de la información. Los datos serán conservados cuando ello sea
necesario para el cumplimiento de una obligación legal o contractual. Una vez cumplida la
finalidad del tratamiento y los términos establecidos anteriormente, se procederá a la supresión de
los datos.
j) Interpretación integral de los derechos constitucionales: Los derechos se interpretarán en
armonía y en un plano de equilibrio con el derecho a la información previsto en el artículo 20 de la
Constitución y con los derechos constitucionales aplicables.
k) Principio de Necesidad: Los datos personales tratados deben ser los estrictamente necesarios
para el cumplimiento de las finalidades perseguidas con la base de datos.</p>

<span className='mb-5 mt-5'>4. CATEGORIZACIÓN DE LOS DATOS</span>

<p>5.1 Datos Sensibles
Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede
generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política,
las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos
humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías
de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos
biométricos.
5.1.1 Tratamiento De Datos Sensibles Se prohíbe el Tratamiento de datos sensibles, excepto cuando:
a) El Titular haya dado su autorización explícita a dicho Tratamiento, salvo en los casos que por ley
no sea requerido el otorgamiento de dicha autorización.
b) El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre
física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar
su autorización.
c) El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa
de un derecho en un proceso judicial.
d) El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán
adoptarse las medidas conducentes a la supresión de identidad de los Titulares.
5.1.2 Autorización Especial De Datos Personales Sensibles
El Ministerio de Ambiente y Desarrollo Sostenible, informará a través de los diversos medios de obtención
de la autorización a todos sus titulares, que en virtud de la ley 1581 del 2012 y normas reglamentarias, en
donde estos no están obligados a otorgar la autorización para el tratamiento de datos sensibles. En caso
de tratamiento de datos relativos a la salud, El Ministerio de Ambiente y Desarrollo Sostenible,
implementará las medidas necesarias para proteger la confidencialidad de la información. Los datos
sensibles biométricos tratados tienen como finalidad la identificación de las personas, la seguridad, el
cumplimiento de obligaciones legales y la adecuada prestación de los servicios.
5.2 Derechos De Los Niños, Niñas Y Adolescentes
Para el tratamiento de datos personales de niños, niñas y adolescentes está prohibido, excepto cuando se
trate de datos de naturaleza pública, y cuando dicho tratamiento cumpla con los siguientes parámetros y/o
requisitos:
• Que respondan y respeten el interés superior de los niños, niñas y adolescentes.
• Que se asegure el respeto de sus derechos fundamentales. Cumplidos los anteriores requisitos, el
representante legal de los niños, niñas o adolescentes otorgará la autorización, previo ejercicio del
menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez,
autonomía y capacidad para entender el asunto.</p>

<span className='mb-5 mt-5'>5. TRATAMIENTO Y FINALIDADES</span>

<p>El Ministerio de Ambiente y Desarrollo Sostenible utilizará la información suministrada única y
exclusivamente para los fines indicados, por lo tanto, salvaguardará las bases de datos que contenga la
información recolectada, y no permitirá el acceso a personal no autorizado, salvo las excepciones
constitucionalesy legales vigentes y aplicables sobre la materia.
En ese sentido tomará todas las precauciones y medidas necesarias para garantizar la reserva de la
información, de conformidad con el principio de confidencialidad que trata la Ley 1581 de 2012, y demás
información vigente sobre la materia. De igual manera el titular asiste los derechos que le otorga la
normatividad aplicable y vigenterespecto a la protección de datos y el derecho a la información.
El tratamiento que realizará el Ministerio de Ambiente y Desarrollo Sostenible será el de recolectar,
almacenar, procesar, usar y transmitir o transferir (según corresponda) los datos personales, atendiendo
de forma estricta los deberes de seguridad y confidencialidad ordenados por la Ley 1581 de 2012 y el
Decreto 1377de 2013, con las siguientes finalidades:
a. Registrar la información de datos personales en las bases de datos delMinisterio
de Ambiente y Desarrollo Sostenible, con la finalidad de analizar, evaluar y
generar datos estadísticos, así como indicadores sectoriales para la formulación
de políticas en el sector ambiental.
b. Facilitar la implementación de programas en cumplimiento de mandatos legales.
c. Enviar la información a entidades gubernamentales o judiciales por solicitud
expresa de las mismas.
d. Soportar procesos de auditoría externa e interna.
Así mismo, el Ministerio de Ambiente y Desarrollo Sostenible suministrará los datos personales a terceros
que le provean servicios o con quien tenga algún tipo de relación de cooperación, a fin de:
a. Brindar asistencia técnica.
b. Facilitar la implementación de programas en cumplimiento de mandatos legales.
c. Manejar y administrar bases de datos.
d. Dar respuestas a peticiones, quejas y recursos.
e. Dar respuestas a organismos de control.
Cuando MINAMBIENTE reciba información que le haya sido transferida por otras entidades debido a su
solicitud o por las entidades territoriales certificadas, como último interviniente en la cadena informativa
del sector ambiental que inicia cuando los titulares dan su información personal a representantes de las
autoridades ambientales le dará el mismo tratamiento de confidencialidad y seguridad que le proporciona a
la información producida por él mismo.
En este sentido y como órgano rector de política en el sector ambiental, MINAMBIENTE instruirá a las
respectivas autoridades ambientales certificadas sobre:
6.Su calidad de responsables de tratamiento de los datos por ellas recaudados.
7.La vigilancia que deben ejercer en cuanto al respeto del habeas data en la relación establecida
entre autoridades ambientales, y demás entes gubernamentales y privados.</p>

<span className='mb-5 mt-5'>7. DERECHOS Y CONDICIONES JURIDICAS PARA EL TRATAMIENTO</span>

<p>7.1 Derechos Del Titular De Los Datos Personales
Como titular de datos personales, se tiene derecho a:
a. Acceder en forma gratuita a los datos proporcionados al Ministerio de Ambiente y Desarrollo
Sostenible que hayan sido objeto de tratamiento.
b. Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos,
fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido.
c. Presentar queja ante la Superintendencia de Industria y Comercio por infracciones a lo dispuesto
en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen, una
vez haya agotado el trámite de reclamo ante el responsable o encargado del tratamiento de datos
personales.
d. Solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y
garantías constitucionales y legales, el cual procederá cuando la autoridad haya determinado que
Ministerio de Ambiente y Desarrollo Sostenible en el tratamiento ha incurrido en conductas
contrarias a la Constitución y la normatividad vigente.
e. Conocer la política de tratamiento de datos de la entidad y a través de ella, el uso o finalidad que
se le dará a sus datos personales.
f. Identificar al responsable en MINAMBIENTE que dará trámite y respuesta a sus solicitudes.
g. Los demás señalados por el artículo 8 de la Ley 1581 de 2012</p>

<span className='mb-5 mt-5'>8. ÁREA RESPONSABLE DE LA ATENCIÓN DE PETICIONES, CONSULTAS Y
RECLAMOS SOBRE DATOS PERSONALES</span>

<p>El Ministerio de Ambiente y Desarrollo Sostenible tiene a su cargo la labor de desarrollo, implementación,
capacitación y seguimiento de esta Política
La Unidad de Atención al Ciudadano es la dependencia que ha sido designada por el Ministerio de
Ambiente y Desarrollo Sostenible como la responsable de la atención de peticiones, consultas, quejas y
reclamos ante la cual el Titular de los datos personales podrá ejercer sus derechos a conocer, actualizar y
rectificar el dato.
Para el efecto, todos los servidores y contratistas que realizan el Tratamiento de Datos Personales en las
diferentes dependencias están obligados a dar traslado a la Unidad de Atención al Ciudadano, de todas
las respuestas de las peticiones, quejas o reclamos que se reciban por parte de los Titulares de Datos
Personales en los tiempos establecidos por la ley.</p>


<span className='mb-5 mt-5'>9. PROCEDIMIENTO PARA ATENCIÓN Y RESPUESTA A PETICIONES, CONSULTAS,
QUEJAS Y RECLAMOS DE LOS TITULARES DE DATOS PERSONALES</span>


<p>Los Titulares de los Datos Personales que estén siendo recolectados, almacenados, procesados, usados
y transmitidos o transferidos por el Ministerio de Ambiente y Desarrollo Sostenible, podrán ejercer en
cualquier momento sus derechos a conocer, actualizar y rectificar la información.
Para el efecto, se seguirá el siguiente procedimiento, de conformidad con la Ley de Protección de Datos
Personales:
9.1 Medios habilitados para la presentación de peticiones, consultas, quejas y reclamos
El Ministerio de Ambiente y Desarrollo Sostenible ha dispuesto los siguientes medios para la recepción y
atención de peticiones, consultas, quejas y reclamos que permiten conservar prueba de estas:
• Comunicación escrita dirigida al MINISTERIO DE AMBIENTE y DESARROLLO SOSTENIBLE.
Unidad de Atención al Ciudadano, Calle 37 No. 8 - 40 en la ciudad de Bogotá D.C.
• Comunicación telefónica: línea gratuita fuera de Bogotá: 018000915060 de lunes a viernes de 8:00
a.m. a 5:00 p.m. Línea gratuita Bogotá +57 (1) 3323400 de lunes a viernes de 8:00 a.m. a 6:00
p.m. PBX: +57 (1) 3323422: De lunes a viernes de 8:00 a.m. a 6:00 p.m. Fax: +57 (1)
• Chat virtual (minambiente.gov.co)
• Solicitud vía correo electrónico: servicioalciudadano@minambiente.gov.co
Sitio Web www.minambiente.gov.co / atención al ciudadano, opción consultas en línea
9.2. Atención y respuesta a peticiones y consultas
El Titular o su apoderado, podrán solicitar al Ministerio de Ambiente y Desarrollo Sostenible:
9.2.1 Información sobre los Datos Personales del Titular que son objeto de Tratamiento.
9.2.2 Información respecto del uso que se le ha dado por el Ministerio de Ambiente y Desarrollo
 Sostenible a sus datos personales.
Las peticiones y consultas serán atendidas en un término máximo de diez (10) días hábiles contados a
partir de la fecha de recibo de las mismas. Cuando no fuere posible atender la petición o consulta dentro
de dicho término, se informará al interesado, expresando los motivos de la demora y señalando cuando se
atenderá su petición o consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes
al vencimiento del primer término.
Cuando la petición o consulta se relacione directamente con los entes territoriales en su condición de
responsables de tratamiento de los datos por ellos recaudados, o sobre la vigilancia que deben ejercer
estos últimos en cuanto al respeto del habeas data en la relación establecida entre autoridades
ambientales, o cualquier otra entidad pública o privada el Ministerio de Ambiente y Desarrollo Sostenible
informará de dicha situación, tanto al interesado peticionario, como al responsable específico de
tratamiento de los datos, remitiéndole de oficio e inmediatamente la respectiva petición a la entidad
territorial certificada pertinente para lo de su competencia y responsabilidad.
9.3. Atención y respuesta a quejas y reclamos
El Titular o sus apoderados, podrán solicitar al Ministerio de Ambiente y Desarrollo Sostenible, a través de
una queja o reclamo presentado mediante los canales ya indicados:
9.3.1 La corrección o actualización de la información.
9.3.2 Que se subsane o corrija el presunto incumplimiento a cualquiera de los deberes
contenidos en la Ley de Protección de Datos Personales.
La solicitud deberá contener como mínimo la descripción de los hechos que dan lugar a la queja o
reclamo, la dirección y datos de contacto del solicitante. Si la queja o reclamo se presentan incompletos,
el Ministerio de Ambiente y Desarrollo Sostenible deberá requerir al interesado dentro de los cinco (5)
días siguientes a la recepción de la queja o reclamo para que subsane las fallas. Transcurridos dos (2)
meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se
entenderá que ha desistido de la queja o reclamo.
En caso que la dependencia que reciba la queja o reclamo no sea competente para resolverla, deberá dar
traslado a la Unidad de Atención al Ciudadano para que la remita al área que corresponda en el Ministerio,
en un término máximo de dos (2) días hábiles e informará de lo ocurrido al interesado.
Una vez recibida la queja o reclamo completo, se incluirá en la Base de Datos, en el aparte
correspondiente, una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no
mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que la queja o reclamo sea
resuelto.
El término máximo para atender la queja o el reclamo será de quince (15) días hábiles contados a partir
del día siguiente a la fecha de su recibo. Cuando no fuere posible atender la queja o el reclamo dentro de
dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá la queja
o reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del
primer término.</p>

<span className='mb-5 mt-5'>10. REGISTRO NACIONAL DE BASES DE DATOS</span>

<p>Dando cumplimiento al Articulo 25 de la Ley 1581 de 2012. El Ministerio de Ambiente y Desarrollo
Sostenible, registrara sus bases de datos junto con la presente política de tratamiento y protección de
datos personales, en el Registro Nacional de bases de datos, administrado por la Superintendencia de
industria y comercio, de conformidad con el procedimiento establecido para tal efecto.</p>
<span className='mb-5 mt-5'>11. PERIODO Y VIGENCIAS DE LAS BASES DE DATOS</span>

<p>Todas las bases de datos de propiedad del Ministerio de Ambiente y Desarrollo Sostenible tendrán el
periodo y vigencia correspondiente con la finalidad para el cual se autorizó su tratamiento y de las normas 
especiales que regulen la materia, así aquellas que establezcan el ejercicio de las funciones legales
asignadas a la Entidad.</p>

<span className='mb-5 mt-5'>12. SENSIBILIZACIÓN DE FUNCIONARIOS Y COLABORADORES</span>

<p>El Ministerio de Ambiente y Desarrollo Sostenible, en conjunto con el equipo de Seguridad de la
Información, desarrollara programas tendientes a la capacitación, sensibilización y apropiación en
protección de datos personales, lo anterior con el fin de dar a conocer esta política, con una periodicidad
anual.
Desde el área de Talento Humano, deberá asegurar que los funcionarios, contratistas y terceros
conozcan su responsabilidad con respecto a la protección de datos personales; Se debe establecer los
planes de capacitación y evaluación de los empleados, teniendo en cuenta los cambios normativos que se
vayan actualizando, con el fin de que los planes sean actualizados de manera periódica.
Teniendo en cuenta los cambios de personal al interior del Ministerio, se debe impartir una capacitación
inicial de apropiación sobre esta política, dejando evidencia de su participación y apropiación del
conocimiento.</p>
<span className='mb-5 mt-5'>13. VIGENCIA</span>
<p>Esta Política de Tratamiento y Protección de Datos Personales entrara en vigencia una vez aprobado y
publicado dicho documento.</p>
<span>14. DOCUMENTOS RELACIONADOS</span>
<p>• El Reglamento General de Protección de Datos (RGPD)
• Decreto Único 1074 de 2015
• Decreto 090 del 18 de enero de 2018
• Ley 1266 de 2008
• Ley 1581 de 2012</p>
<span className='mb-5 mt-5'>15. GLOSARIO</span>

<p>Aviso de Privacidad: Comunicación verbal o escrita generada por el responsable, dirigida al Titular para
el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las
Políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las
finalidades del tratamiento que se pretende dar a los datos personales.
Base de Datos: Conjunto organizado de datos personales que sea objeto de tratamiento.
Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales
determinadas o determinables.
Dato Sensible: Información que afectan la intimidad de las personas o cuyo uso indebido puede generar
discriminación (Origen racial o étnico, orientación política, convicciones filosóficas o religiosas, pertinencia
a sindicatos u organizaciones sociales o derechos humanos, datos de salud, vida sexual y biométricos)
Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio
con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento. En los
eventos en que el responsable no ejerza como Encargado de la base de datos, se identificará
expresamente quién será el Encargado.
Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en
asocio con otros, decida sobre la base de datos o el tratamiento de los datos.
Titular: Persona natural cuyos datos personales sean objeto de tratamiento.
Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la
recolección, almacenamiento, uso, circulación o supresión.
Transferencia: La transferencia de datos tiene lugar cuando el responsable o encargado del tratamiento
de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que
a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.
Transmisión: Tratamiento de datos personales que implica la comunicación de estos dentro o fuera del
territorio de la República de Colombia cuando tenga por objeto la realización de un tratamiento por el
encargado por cuenta del responsable.
</p>

                </div>



            </div>



    );

};

export default Datos;
